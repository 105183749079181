import React from "react";
import Container from "../components/Container/Container";
import Seo from "../components/Seo/Seo";
import G from "../images/groenhout_g.png"
import Image from "../images/groenhout-assen-1.jpg"
import { useVestigingenQuery } from "../hooks/useVestigingenQuery";
// import VestigingContext from "../store/vestiging-context";
import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew";
import GravityForm from "../components/GravityForm";

const Page = () => {

    const mapMarker = <svg width="123" height="123" viewBox="0 0 123 123" fill="none" className="mx-auto" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5_382)"><path d="M61.4998 68.8286C63.5996 68.8286 65.6789 68.415 67.6189 67.6115C69.5589 66.8079 71.3216 65.6301 72.8064 64.1453C74.2912 62.6605 75.469 60.8977 76.2726 58.9577C77.0762 57.0177 77.4898 54.9385 77.4898 52.8386C77.4898 50.7388 77.0762 48.6595 76.2726 46.7195C75.469 44.7795 74.2912 43.0168 72.8064 41.532C71.3216 40.0472 69.5589 38.8694 67.6189 38.0658C65.6789 37.2622 63.5996 36.8486 61.4998 36.8486C57.259 36.8486 53.1918 38.5333 50.1931 41.532C47.1944 44.5307 45.5098 48.5978 45.5098 52.8386C45.5098 57.0794 47.1944 61.1466 50.1931 64.1453C53.1918 67.144 57.259 68.8286 61.4998 68.8286Z" stroke="#F8F8F8" stroke-width="11.5"/><path d="M18.5527 43.5113C28.649 -0.871238 94.4027 -0.819987 104.448 43.5625C110.341 69.5975 94.1465 91.635 79.9502 105.268C74.9896 110.052 68.3911 117.875 61.4992 117.875C54.6073 117.875 47.9596 110.052 42.999 105.268C28.854 91.635 12.659 69.5463 18.5527 43.5113Z" stroke="#F8F8F8" stroke-width="11.5"/></g><defs><clipPath id="clip0_5_382"><rect width="123" height="123" fill="white"/></clipPath></defs></svg>

    const data = useVestigingenQuery()
    const seo = useStaticQuery(
        graphql`
        query ContactPage {
            wpPage(slug: {eq: "contact"}) {
                seo{
                    metaDesc
                    title
                  }
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
        } `
      )
    const image = seo.wpPage.featuredImage.node.mediaItemUrl
    
    const vestigingData = data.allWpVestiging.nodes.sort((a,b) => a.title.localeCompare(b.title))
    const filtered = vestigingData.filter((e) => {
      if(e.vestigingen.showVestiging !== null){
          return true;
      }else{
          return false;
      }
    })
    
    const vestigingen = filtered.map(( vestiging, i ) => (
        <motion.div initial={{translateY: -100, opacity: 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.3, delay: i * .25 + .3 }} key={i} className={`lg:pr-8 md:pr-4 ${i > 2 ? `` : ``}`}>
            <div className="px-8 py-12 bg-blue relative overflow-hidden text-center">
                {mapMarker}
                <h3 className="text-light text-center mb-0 mt-4 uppercase font-black text-xl lg:text-2xl">{vestiging.title}</h3>
                <img src={G} alt="G-logo" className="absolute -bottom-14 m-0 -left-6 h-24" />
            </div>
            <div>
                <h3 className="text-blue text-bold mb-4 mt-5 text-base">Team {vestiging.title}</h3>
                <div className="text-gray flex flex-col text-sm">
                    <span>{vestiging.vestigingen.vestigingAdres}</span>
                    <span>{vestiging.vestigingen.vestigingPostcode}</span>
                </div>
                <div className="mt-4 flex flex-col text-sm">
                    <a className="hover:text-red transition-all duration-300" href={`tel:${vestiging.vestigingen.vestigingTelefoonnummer}`}>{vestiging.vestigingen.vestigingTelefoonnummer}</a>
                    <a className="hover:text-red transition-all duration-300" href={`mailto:${vestiging.vestigingen.vestigingEmailadres}`}>{vestiging.vestigingen.vestigingEmailadres}</a>
                </div>
            </div>
           
        </motion.div>
    ))

    return(
        <>
            <Seo title={seo.wpPage.seo.title} description={seo.wpPage.seo.metaDesc}></Seo>
            {/* <SmallHero title="Contact" desc="Wil je meer informatie? Neem contact met ons op via het onderstaande formulier" overlay={true} overlayImage={G} image={image !== null ? image : Image}></SmallHero> */}
            <SmallHeroNew title="Contact" desc="Kies voor een makelaar die knokt voor jouw belangen." overlay={true} overlayImage={G} image={image !== null ? image : Image}></SmallHeroNew>
            <div className="bg-white px-8 lg:px-0">
                <Container>
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-8 lg:gap-16 text-blue xl:px-24 lg:px-4 md:px-0 pt-16 w-10/12 mx-auto">
                        {vestigingen}
                    </div>
                </Container>
            </div>
            <div className="bg-light py-8 lg:py-16 mt-16 px-8 lg:px-0">
                <Container>
                    <div className="p-8 bg-white w-full lg:w-2/5 mx-auto">
                        <h2 className="text-blue text-3xl">Neem contact met ons op!</h2>
                        <GravityForm id={'1'} colorClass={`bg-light`}></GravityForm>
                    </div>
                </Container>
            </div>
            </>
    )
}

export default Page;